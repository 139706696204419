
import { ref, computed, watch, onMounted } from "vue";
import mediaHelper from "@libraryHelpers/media.js";
import { useMedia } from "@libraryComposables/useMedia";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import { useRoot } from "@libraryHelpers/compositionApi";

export default {
	name: "CoreBlockHero",
	props: {
		slide: {
			type: Object,
			default: () => ({
				src: "",
				focal: {},
			}),
		},
		slideIndex: {
			type: Number,
			default: null,
		},
		currentSlide: {
			type: Number,
			default: 0,
		},
		componentType: {
			type: String,
			default: "contact-card",
		},
	},
	setup(props) {
		const croppedImage = ref(null);
		const videoPaused = ref(true);
		const itemCheckFunction = (item) =>
			item.propertyKey === "heroBackgroundMediaSrc";
		const { isExpired } = useIsExpired(props.slide, itemCheckFunction);
		const mediaSrc = computed(() => props.slide?.src);
		const {
			isEMoney: isEMoneyUrl,
			isImage: mediaIsImg,
			isVideo: mediaIsHostedVideo,
		} = useMedia(mediaSrc);
		const root = useRoot();
		// create video computed const that references the video element in the template
		const video = computed(() => {
			return root.$refs.video;
		});

		const background = computed(() => {
			return {
				"background-image": `url(${
					croppedImage.value ||
					mediaHelper.getEncodedURI(props.slide?.src)
				})`,
				"background-position": props.slide?.focal?.bgPosition,
				"background-repeat": "no-repeat",
				"background-size": "cover",
				height: "inherit",
			};
		});

		const toggleVideoStatus = () => {
			if (video.value) {
				video.value.paused ? video.value.play() : video.value.pause();
				videoPaused.value = video.value.paused;
			}
		};

		watch(
			() => props.currentSlide,
			(nv) => {
				if (!mediaIsImg.value && !mediaIsHostedVideo.value) {
					if (nv == props.slideIndex) {
						video.value.play();
						videoPaused.value = false;
					} else {
						video.value.pause();
						videoPaused.value = true;
					}
				}
			}
		);

		onMounted(() => {
			if (
				mediaIsImg.value ||
				mediaIsHostedVideo.value ||
				isEMoneyUrl.value
			)
				emitBackgroundReady();
			else {
				video.value.addEventListener("canPlay", emitBackgroundReady);
			}
			if (
				!mediaIsImg.value &&
				!mediaIsHostedVideo.value &&
				props.slideIndex === 0
			)
				videoPaused.value = false;
		});

		const emitBackgroundReady = () => {
			root.$emit("backgroundReady");
		};

		return {
			croppedImage,
			videoPaused,
			isExpired,
			isEMoneyUrl,
			mediaIsImg,
			mediaIsHostedVideo,
			video,
			background,
			toggleVideoStatus,
		};
	},
};
